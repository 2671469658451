import React from "react"
import Header from "../components/Header";
import HomeContent from "../components/HomeContent";
import Title from "../components/Title";
import Footer from "../components/Footer";

export default function Index() {
  return (
    <>
      <Header />
      <Title />
      <HomeContent />
      <Footer />
    </>
  )
}
