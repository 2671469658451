import React from "react"

export default function Footer() {
  return (
    <div className="ezds-footer">
      <span>&copy;{' by Villa Fedrita'}</span>
      <span>{'Made by EZ Code'}</span>
    </div>
  )
}
