import React from "react"
import Image from "../Image/index"
import { Desktop, Mobile, Tablet } from "../../utils/breakpoints"

export default function HomeContent() {
  return (
    <div className="ezds-home-content">
      <div className="ezds-home-row">
        <div className="ezds-home-column">
          <Image fileName="home-1.jpg" style={{ width: "90%" }} alt="" />
        </div>
        <div className="ezds-home-column">
          <div className="ezds-home-card">
            <span className="ezds-home-card-title">
              {`YOUR PRIVATE GETAWAY \n IN ACHARAVI - CORFU.`}
            </span>
            <span className="ezds-home-card-content">
              {`Villa Fedrita is a beautifully 3 bedroom, 2 bathroom villa, with full airconditioning, private south-faced pool and free wi-fi, that appeals to couples and families. It is located in peaceful surroundings with views to mountains, sea and fabulous sunsets.`}
            </span>
            <div className="ezds-home-card-button">{"Book your stay"}</div>
          </div>
        </div>
      </div>
      <div className="ezds-home-row">
        <div className="ezds-home-card-full">
          <Mobile>
            <div className="ezds-home-card-item">
              <Image
                fileName="home-2.jpg"
                style={{ width: "100%", height: "400px" }}
                alt=""
              />
            </div>
          </Mobile>
          <Tablet>
            <div className="ezds-home-card-item">
              <Image
                fileName="home-2.jpg"
                style={{ width: "100%", height: "400px" }}
                alt=""
              />
            </div>
          </Tablet>
          <div className="ezds-home-card-item">
            <span className="ezds-home-card-item-title ezds-home--row">{`perfection!`}</span>
            <span className="ezds-home-card-item-content ezds-home--row">{`"This villa is everything you could wish for, for both couples and families. Very well equipped there is everything you could want on your holiday. You can relax by the pool or just sit on one of the many furnished balconies and while away the day. We watched the sun set from the balcony every night and it was stunning. The photos actually don't do the place justice it is even more beautiful in reality."`}</span>
            <span className="ezds-home-card-item-signature ezds-home--row">{`Julia F. - 16 jul. 2016`}</span>
          </div>
          <div className="ezds-home-card-item">
            <span className="ezds-home-card-item-title ezds-home--row">{`PERFECT HONEYMOON!`}</span>
            <span className="ezds-home-card-item-content ezds-home--row">{`"The property was exactly what the pictures showed it to be. My husband and I were very pleased with the villa and all the amenities available. I would definitely recommend staying at Villa Fedrita."`}</span>
            <span className="ezds-home-card-item-signature ezds-home--row">{`Anonymous. - 6 jul. 2017`}</span>
          </div>
          <div className="ezds-home-card-item">
            <span className="ezds-home-card-item-title ezds-home--row">{`A WONDERFULL WEEK IN CORFU`}</span>
            <span className="ezds-home-card-item-content ezds-home--row">{`“What a wonderful week. Clear step by step directions from the airport and we were met at a convenient restaurant to be taken up to the villa as it might have been a little hard to find. The villa is more private than the pictures show which is amazing and the sunset views we had were lovely as the sun sets over the sea in the distance. The villa was spotless on our arrival and was also cleaned again while we were there. We would highly recommend this villa to couples or a family.`}</span>
            <span className="ezds-home-card-item-signature ezds-home--row">{`Andrew - London - 14 sept 2013`}</span>
          </div>
          <Desktop>
            <div className="ezds-home-card-item">
              <Image
                fileName="home-2.jpg"
                style={{ width: "600px", height: "600px" }}
                alt=""
              />
            </div>
          </Desktop>
        </div>
      </div>
    </div>
  )
}
